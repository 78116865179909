import React from "react";
import './AboutMe.css'

function AboutMe (){
    return (
        <div className="about-me-container">
        <h1>About Me</h1>
        <p>
        Born and raised in the vibrant city of Santa Ana, my earliest memories are sprinkled with the sweet aroma of baking, 
        a passion that blossomed from the tender age of curiosity. As a little girl, I found solace and joy in the kitchen, 
        where ingredients came together to create something magical.
        </p>
        <p>
        My journey through life took me down the path of nursing school, a time of intense learning and growth.
        In the quiet moments between studies, I decided it was time to transform my dreams into reality. 
        This marked the beginning of an adventure—not in the world of medicine, but in the delicate art of custom cake crafting.
        </p>
        <p>
        Each cake was a learning curve, and though the path was strewn with trials, the support and glowing reviews from my clients fueled my pursuit. 
        They weren't just customers; they became the cheerleaders of my passion.
        </p>
        <p>
        Today, I stand before you as a creator of beauty and a curator of taste, dedicated to crafting bespoke cake masterpieces for your 
        most cherished celebrations. It is more than a passion—it is a calling. To create beautiful and delicious custom cakes that mark the
        milestones of your life is my life's work, and I invite you to savor the joy of these edible expressions of art.
        </p>

      </div>
    )
}

export default AboutMe;