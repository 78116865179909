import React from "react";
import CarouselCake from "./CarouselCake";
import AboutMe from "../aboutme/AboutMe";

function LandingPage(){
    return(
        <div>
        <CarouselCake />
        <AboutMe />
    </div>
    )
}
export default LandingPage