import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import createCakeSchema from "../schemas/createCakeSchema";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from 'sweetalert2'; 
import { cakeFormService, cakeImageService } from "../services/cakeFormService";
import emailjs from 'emailjs-com';


function CakeForm({ onFormSubmit, selectedDate, closeModal }) {
    const initialFormValues = {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        dateOfEvent: selectedDate ? selectedDate.toISOString().split('T')[0] : "",
        cakeType: "",
        cakeSize: "",
        cakeFlavor: "",
        cakeFilling: "",
        description: "",
        imageUrl: "" 
    };

    const cakeOptions = {
        Round: [
            { label: '6 inch (8-10 servings)', value: '6-inch' },
            { label: '8 inch (12-15 servings)', value: '8-inch' },
            { label: '10 inch (20-25 servings)', value: '10-inch' },
        ],
        HeartShaped: [
            { label: '6 inch (8-10 servings)', value: '6-inch' },
            { label: '8 inch (12-15 servings)', value: '8-inch' },
            { label: '10 inch (20-25 servings)', value: '10-inch' },
        ],
        Tiered: [
            { label: '6 inch + 8 inch (30-35 servings)', value: '6-8-inch' },
            { label: '8 inch + 10 inch (45-50 servings)', value: '8-10-inch' },
            { label: '6 inch + 8 inch + 10 inch (75-80 servings)', value: '6-8-10-inch' },
        ],
        NumberCakes: [
            { label: 'Double Digit (20-25 servings)', value: 'double-digit' },
            { label: 'Mini Double Digit (12-15 servings)', value: 'mini-double-digit' },
        ],
        LetterCakes: [
            { label: 'Double Digit (20-25 servings)', value: 'double-digit' },
        ]
    };

    const cakeFlavorOptions = [
        { value: 'vanilla', label: 'Vanilla' },
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'marble', label: 'Marble' },
        { value: 'red velvet', label: 'Red Velvet' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'funfetti', label: 'Funfetti' },
    ];

    const cakeFillingOptions = [
        { value: 'buttercream', label: 'Buttercream' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'peach', label: 'Peach' },
        { value: 'oreo', label: 'Oreo' },
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'white chocolate', label: 'White Chocolate' },
        { value: 'cream cheese', label: 'Cream Cheese' },
    ];

    const [cakeSizes, setCakeSizes] = useState([]);
    const [uploadedImageUrl, setUploadedImageUrl] = useState("");

    useEffect(() => {
       
    }, []); 

    const handleFileChange = (e, setFieldValue) => {
        const file = e.target.files[0];
        if (!file) return;
        const formData = new FormData();
        formData.append('file', file);
        cakeImageService.uploadImage(formData).then(response => {
            const imageUrl = response.data.url; 
            setUploadedImageUrl(imageUrl);
            setFieldValue('imageUrl', imageUrl); 
        }).catch(error => {
            console.error("Error uploading image:", error);
        });
    };
    
    const handleCakeTypeChange = (event, setFieldValue) => {
        const type = event.target.value;
        const sizes = cakeOptions[type] || [];
        setCakeSizes(sizes);
        setFieldValue('cakeType', type);
        setFieldValue('cakeSize', '');
    };

    const handleSubmit = (values, { setSubmitting }) => {
        const emailParams = {
            from_name: values.firstName + " " + values.lastName,
            email: values.email,
            phone_number: values.phoneNumber,
            date_of_event: values.dateOfEvent,
            cake_type: values.cakeType,
            cake_size: values.cakeSize,
            cake_flavor: values.cakeFlavor,
            cake_filling: values.cakeFilling,
            description: values.description,
            image_url: values.imageUrl,
        };
        emailjs.send('service_c5jfkuq', 'template_fn1uhta', emailParams, 'KybkxB9UV1TzVp9C4')
        .then((emailResponse) => {
            console.log('Email sent successfully!', emailResponse.status, emailResponse.text);
            onFormSubmit(values);
            cakeFormService.submitCakeOrder(values)
                .then(response => {
                    Swal.fire({
                        title: "Great job!",
                        text: "Your Cake Order has been submitted successfully!",
                        icon: "success",
                    });
                   
                })
                .catch(error => {
                    console.error("Cake order submission error:", error);
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Your Cake order has failed!",
                    });
                })
                .finally(() => {
                    setSubmitting(false);
                    closeModal(); 
                });
        })
        .catch((emailError) => {
            console.error("Email send error:", emailError);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "There was a problem sending the confirmation email.",
            });
        });

    };

    return (
       
        <Formik
        initialValues={initialFormValues}
        validationSchema={createCakeSchema}
        onSubmit={handleSubmit} 
        >
            {({ values,setFieldValue }) => (
                <Form>
                     <div className="mb-3">
                                <label htmlFor="firstName" className="discounts-form-label"> First Name</label>
                                <Field name="firstName" type="text" className="form-control"/>
                                <ErrorMessage name="firstName" component="div"  className="text-danger"/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="lastName" className="discounts-form-label"> Last Name</label>
                                <Field name="lastName" type="text" className="form-control"/>
                                <ErrorMessage name="lastName" component="div"  className="text-danger"/>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="email" className="discounts-form-label"> Email</label>
                                <Field name="email" type="text" className="form-control"/>
                                <ErrorMessage name="email" component="div"  className="text-danger"/>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="phoneNumber" className="discounts-form-label"> Phone Number</label>
                                <Field name="phoneNumber" type="text" className="form-control"/>
                                <ErrorMessage name="phoneNumber" component="div"  className="text-danger"/>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="dateOfEvent" className="discounts-form-label"> Date of your event</label>
                                <Field name="dateOfEvent" type="date" className="form-control discounts-form-date"/>
                                <ErrorMessage name="dateOfEvent" component="div"  className="text-danger"/>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="cakeType">Cake Type</label>
                                <Field as="select" name="cakeType" className="form-control"
                                onChange={(event) => handleCakeTypeChange(event, setFieldValue)}
                                >
                                <option value="">Select a cake type</option>
                                {Object.keys(cakeOptions).map((type) => (
                                <option key={type} value={type}>
                                {type}
                                </option>
                                ))}
                                </Field>
                                <ErrorMessage name="cakeType" component="div" className="text-danger" />
                            </div>

                    <div className="form-group">
                        <label htmlFor="cakeSize">Cake Size</label>
                        <Field name="cakeSize" as="select" className="form-control">
                            <option value="">Select Cake Size</option>
                            {cakeSizes.map(size => (
                                <option key={size.value} value={size.value}>{size.label}</option>
                            ))}
                        </Field>
                        <ErrorMessage name="cakeSize" component="div" className="text-danger" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="cakeFlavor">Cake Flavor</label>
                        <Field name="cakeFlavor" as="select" className="form-control">
                            <option value="">Select Cake Flavor</option>
                            {cakeFlavorOptions.map(option => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </Field>
                        <ErrorMessage name="cakeFlavor" component="div" className="text-danger" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="cakeFilling">Cake Filling</label>
                        <Field name="cakeFilling" as="select" className="form-control">
                            <option value="">Select Cake Filling</option>
                            {cakeFillingOptions.map(option => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </Field>
                        <ErrorMessage name="cakeFilling" component="div" className="text-danger" />
                    </div>

                    <div className="form-group">
                  <label htmlFor="description" type="text" className="form-label">
                    Description
                  </label>
                  <CKEditor
                        editor={ClassicEditor}
                        data={values.description} 
                        onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue("description", data); 
                        }}
                        />

                        <ErrorMessage
                        name="description"
                        component="div"
                        className="has-error"
                        />

                </div>

                    <div className="form-group">
                        <label htmlFor="imageFile">Cake Image</label>
                        <input
                            id="imageFile"
                            name="imageFile"
                            type="file"
                            onChange={(e) => handleFileChange(e, setFieldValue)}
                            className="form-control"
                        />
                        {uploadedImageUrl && (
                            <img src={uploadedImageUrl} alt="Uploaded Cake" style={{ maxWidth: '80%', height: 'auto' }} />

                        )}
                    </div>

                    <button type="submit" className="btn btn-primary">Submit</button>
                    <button type="button" className="btn btn-secondary" onClick={closeModal}>Cancel</button>
                </Form>
            )}
        </Formik>
    );
}

export default CakeForm;
