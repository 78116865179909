import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import BlockedDates from './BlockedDates';
import AdminReservationTable from './AdminReservationTable';

const AdminDashboard = () => {
    const navigate = useNavigate();

    const handleSignOut = () => {
        localStorage.removeItem('isAdminAuthenticated');
        navigate('/home');
      };
  return (
    <Container className="mt-5">
      <h1>Admin Dashboard</h1>
      <Button onClick={handleSignOut} variant="secondary" className="mb-4">
        Sign Out
      </Button>
      <Row className="mt-4">
        <Col>
          <BlockedDates /> 
          <AdminReservationTable/>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminDashboard;
