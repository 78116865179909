import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import loginFormService from '../services/loginFormService';
import Swal from 'sweetalert2'; 
import { useNavigate } from 'react-router-dom';


const LoginForm = () => {
    console.log("LoginForm is rendering");
    const [username, setUsername] = useState(''); 
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await loginFormService.login(username, password);
      console.log(response.data);
      if (response.data && response.data.message === "Authentication successful") {
        localStorage.setItem('isAdminAuthenticated', 'true');
        Swal.fire({
          title: "Great job!",
          text: "Welcome Mary!",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            console.log('Navigating to /admin/dashboard');
            navigate('/admin/dashboard');
          }
        });
      } else {
        throw new Error('Authentication failed');
      }
    } catch (error) {
      console.error("Authentication failed:", error.response || error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Login has failed!",
      });
    }
  };

  return (
    <Form onSubmit={handleLogin}>
      <Form.Group className="mb-3" controlId="formBasicUsername"> 
        <Form.Label>Username</Form.Label> 
        <Form.Control 
          type="text" 
          placeholder="Enter username" 
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required />
        <Form.Text className="text-muted">
          We'll never share your username with anyone else.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control 
          type="password" 
          placeholder="Password" 
          value={password}
          onChange={(e) => setPassword(e.target.value)} 
          required />
      </Form.Group>
      
      <Button variant="primary" type="submit">
        Login
      </Button>
    </Form>
  );
};

export default LoginForm;

