import axios from "axios";

const blockedDatesService = {
    endpoint:"https://cakesbymaryy.azurewebsites.net/api/block-dates" 
};

blockedDatesService.gettingAllDates = () => {
    const config = {
        method: "GET",
        url: blockedDatesService.endpoint,
        withCredentials: true,
        headers: { "Content-Type": "application/json" }
    };
    return axios(config);
};

blockedDatesService.blockingDate = (date) => {
    const config = {
        method: "POST",
        url: blockedDatesService.endpoint,
        data: { blockedDateString: date },
        withCredentials: true,
        headers: { "Content-Type": "application/json" }
    };
    return axios(config);
};



blockedDatesService.removeDates = (id) => {
    const config = {
        method: "DELETE",
        url: `${blockedDatesService.endpoint}/${id}`,
        withCredentials: true,
        headers: { "Content-Type": "application/json" }
    };
    return axios(config);
};

export default blockedDatesService;
