import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { FaInstagram } from "react-icons/fa";
import './contact.css';
import CakeForm from '../orderCake/CakeForm';
import { Modal, Button } from 'react-bootstrap';
import reservationService from '../services/reservationService';
import blockedDatesService from '../services/blockedDatesService'; 

function ContactAndCalendar() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  const [reservedDates, setReservedDates] = useState([]);
  const [blockedDates, setBlockedDates] = useState([]);

  const fetchReservedDates = useCallback(() => {
    reservationService.getAllReservations()
      .then(response => {
        const fetchedDates = response.data.map(reservation => 
          new Date(reservation.reservationDate).toISOString().split('T')[0]
        );
        setReservedDates(fetchedDates);
      })
      .catch(error => {
        console.error("Error fetching all reservations:", error);
      });
  }, []);

  const fetchBlockedDates = useCallback(() => {
    blockedDatesService.gettingAllDates()
      .then(response => {
        const fetchedDates = response.data.map(date => new Date(date.blockedDate).toISOString().split('T')[0]);
        setBlockedDates(fetchedDates);
        console.log(fetchedDates); 
      })
      .catch(error => {
        console.error("Error fetching blocked dates:", error);
      });
  }, []);

  useEffect(() => {
    fetchReservedDates();
    fetchBlockedDates(); 
  }, [fetchReservedDates, fetchBlockedDates]); 

  const handleReservationSubmit = useCallback((formData) => {
    const startOfDay = new Date(selectedDate.setHours(0, 0, 0, 0));
    const dateInUtc = startOfDay.toISOString();

    const submissionData = {
      ...formData,
      reservationDate: dateInUtc
    };

    reservationService.submitReservation(submissionData)
      .then(() => {
        fetchReservedDates(); 
        setShowModal(false);
        alert("Reservation successfully submitted!");
      })
      .catch(error => {
        console.error("Error submitting reservation:", error);
        alert("Failed to submit reservation.");
      });
  }, [selectedDate, fetchReservedDates]); 

  const handleDayClick = useCallback((value) => {
    const dateString = value.toISOString().split('T')[0];
    if (reservedDates.includes(dateString)) {
      alert("This date is already reserved. Please select another date.");
    } else if (blockedDates.includes(dateString)) {
      alert("This date is blocked. Please select another date.");
    } else {
      setSelectedDate(value);
      setShowModal(true);
    }
  }, [reservedDates, blockedDates]); 

  const tileClassName = useMemo(() => ({ date, view }) => {
    if (view === 'month') {
      const dateString = date.toISOString().split('T')[0];
      return reservedDates.includes(dateString) ? 'reservedDate' : 
             blockedDates.includes(dateString) ? 'blockedDate' : null;
    }
  }, [reservedDates, blockedDates]); 
  

  return (
    <div className="content-body">
      <div className="contact-calendar">
        <div className="contact-info">
          <h2 className="date-info">Contact Information</h2>
          <p>Please check my calendar before placing an order. I do respond on my social media account. Instagram is the quickest way to contact me.</p>
          <p>Please remember that I do get booked out weeks in advance and it could take up to 1-3 days for me to respond to new messages. Rush orders typically do not get filled. Please check below for available dates before messaging.</p>
          <p className="calendar-note">Note: Dates highlighted in red or grey are already booked or unavailable.</p>
          <div className="social-media">
            <a href="https://www.instagram.com/cakesbymaryyy/?hl=en" target="_blank" rel="noopener noreferrer">
              <FaInstagram size={30} /> Follow me on Instagram
            </a>
          </div>
        </div>
        <div className="contact-info">
        <h2 className="date-info">How to Order</h2>
    <p>To place an order, simply click on a date in the calendar that is not highlighted in red. Dates not highlighted are available for booking. Once you select an available date, a form will appear where you can provide details about your cake order. After submitting the form, I will get back to you to confirm the order and provide further instructions.</p>
    <p>Please ensure you select a date giving enough lead time as I am often booked weeks in advance. This helps in ensuring that your order can be accommodated.</p>
  </div>
        
        <h2 className="date-info">Available Dates</h2>
        <Calendar
          onChange={setSelectedDate}
          value={selectedDate}
          onClickDay={handleDayClick}
          tileClassName={tileClassName} 
        />
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
           <Modal.Title>Book Your Cake</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CakeForm selectedDate={selectedDate} closeModal={() => setShowModal(false)} onFormSubmit={handleReservationSubmit} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ContactAndCalendar;

