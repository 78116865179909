import axios from "axios";

const loginFormService = {
    endpoint:"https://cakesbymaryy.azurewebsites.net/api"

};

loginFormService.login = (username, password) => {
    console.log("Logging in", { username, password });
    const config = {
        method: "POST",
        url: `${loginFormService.endpoint}/login`,
        data: { username, password },
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
    };
    return axios(config);
}

export default loginFormService;
