import React, { useState, useEffect } from 'react';
import reservationService from '../services/reservationService';
import './adminTable.css';

function AdminReservationTable() {
  const [reservations, setReservations] = useState([]);

  useEffect(() => {
    fetchReservations();
  }, []);

  function fetchReservations() {
    reservationService.getAllReservations()
      .then(response => {
        setReservations(response.data);
      })
      .catch(error => {
        console.error("Error fetching reservations:", error);
      });
  }

  function deleteReservation(id) {
    if (window.confirm("Are you sure you want to delete this reservation?")) {
      reservationService.deleteReservation(id)
        .then(() => {
          setReservations(reservations.filter(reservation => reservation.id !== id));
        })
        .catch(error => {
          console.error("Error deleting reservation:", error);
        });
    }
  }

  return (
    <div>
      <h2>Reserved Dates</h2>
      <table className="adminReservationTable-table">
        <thead>
          <tr className="adminReservationTable-tr">
            <th className="adminReservationTable-th">ID</th>
            <th className="adminReservationTable-th">Date</th>
            <th className="adminReservationTable-th">Actions</th>
          </tr>
        </thead>
        <tbody>
          {reservations.map(reservation => (
            <tr className="adminReservationTable-tr" key={reservation.id}>
              <td className="adminReservationTable-td">{reservation.id}</td>
              <td className="adminReservationTable-td">{new Date(reservation.reservationDate).toLocaleDateString()}</td>
              <td className="adminReservationTable-td">
                <button className="adminReservationTable-button" onClick={() => deleteReservation(reservation.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AdminReservationTable;






