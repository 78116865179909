import React from "react";
import './policies.css'; 


function Policies (){
    const VenmoIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
          <path fill="currentColor" d="M14 3.27a7.49 7.49 0 0 1 .66 3.35c0 2.72-1.93 6.72-3.49 9.27L9.53 2.44l-6.91.65L5.79 22h7.88c3.45-4.54 7.71-11 7.71-16a7.3 7.3 0 0 0-1.06-4z"></path>
        </svg>
      );
    return (
        <div className="content-area">
          <h1>Payment Policy</h1>
          
          <section>
            <h2>Deposit Requirements</h2>
            <p>A $50 non-refundable deposit is required to secure your booking. This deposit can be made through the following methods:</p>
            <ul className="payment-details">
      <li className="payment-method">ZELLE: Marysol Roman - 714-797-1556</li>
      <li className="payment-method">Venmo: @MARYSOLR28 - Please use a random emoji for the caption.</li>
      <li className="payment-method">Apple Pay: 714-797-1556</li>
    </ul>
          </section>
          <div className="social-media">

           <a href="https://venmo.com/u/MARYSOLR28" target="_blank" rel="noopener noreferrer" aria-label="Venmo - @MARYSOLR28">
          <VenmoIcon /> Venmo link Here
        </a>
        </div>
          
          <section>
            <h2>Final Payment Policy</h2>
            <p>The balance of your order is due on the evening before your scheduled order date. Upon receipt of the final payment, I will confirm your order details, including the pickup address and time. Please note:</p>
            <ul className="payment-details">
              <li>Payments are not accepted at pickup.</li>
              <li>Cash is not accepted.</li>
              <li>Only Zelle, Venmo, and Apple Pay are accepted.</li>
            </ul>
            <p className="payment-note">Please ensure all payments are completed before pickup to ensure a smooth transaction.</p>
          </section>
        </div>
      );
      
}
export default Policies;