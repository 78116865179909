import React, { Fragment } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Icon from "@mdi/react";
import {  mdiInstagram } from "@mdi/js";
import "./footer.css"

const Footer = () => {
    return (
      <Fragment>
  <div className="pt-lg-10 pt-5 footer">
    <Container>
      <Row className="justify-content-center">
        <Col lg={5} md={8} sm={12} className="text-center">
          <div className="mb-4">
            <h1 className="display-2 fw-bold mb-3">
              CakesByMary
            </h1>
            <p>
              Craving sweetness for your joyous celebrations? Simply send me a message, and let me take care of the rest.
              At CakesByMary, I believe every special moment deserves a slice of happiness. Your dream cake awaits!
            </p>
            <div className="fs-4 mt-4">
            <a href="https://www.instagram.com/cakesbymaryyy/?hl=en" className="mdi mdi-instagram text-muted">
                <Icon path={mdiInstagram} size={1} />
                </a>
            </div>
          </div>
        </Col>
        <Col lg={{ span: 3, offset: 4 }} md={5} className="mb-4 text-lg-end text-md-center">
            <h3 className="fw-bold mb-3">Contact Me</h3>
              <p className="mb-1">
                Email: <a href="mailto:cakesbymaryyy@gmail.com">cakesbymaryyy@gmail.com</a>
              </p>
        </Col>

      </Row>
      <Row className="align-items-center g-0 border-top py-2 mt-6 footer-text">
        <Col lg={12} className="text-center">
          <p>Copyright &#169; 2024 CakesByMary. All Rights Reserved</p>
        </Col>
      </Row>
    </Container>
  </div>
</Fragment>

    );
  };
  
  export default Footer;
  