import React from "react";
import './flavors.css'

function CakeFlavors (){
    return(
<div className="cake-flavors-content" >
  <h2 className="cake-header">Cake Sizing & Approximate Servings</h2>
  
  <div className="round-heart-cakes">
    <h3>Round or Heart Shaped Cakes</h3>
    <ul>
      <li>6 inch (8-10 servings)</li>
      <li>8 inch (12-15 servings)</li>
      <li>10 inch (20-25 servings)</li>
    </ul>
  </div>

  <div className="tiered-cakes">
    <h3>Tiered Cakes</h3>
    <ul>
      <li>6 inch + 8 inch (30-35 servings)</li>
      <li>8 inch + 10 inch (45-50 servings)</li>
      <li>6 inch + 8 inch + 10 inch (75-80 servings)</li>
    </ul>
  </div>

  <div className="number-cakes">
    <h3>Number Cakes</h3>
    <ul>
      <li>Double Digit (20-25 servings)</li>
      <li>Mini Double Digit (12-15 servings)</li>
    </ul>
  </div>

  <div className="letter-cake">
    <h3>Letter Cake</h3>
    <ul>
      <li>Single letter (15-20 servings)</li>
    </ul>
  </div>

  <div className="cake-flavors">
    <h2>Cake Flavors</h2>
    <ul>
      <li>Vanilla</li>
      <li>Chocolate</li>
      <li>Marble</li>
      <li>Red Velvet</li>
      <li>Strawberry</li>
      <li>Funfetti</li>
    </ul>
  </div>

  <div className="fillings">
    <h2>Fillings</h2>
    <ul>
      <li>Buttercream</li>
      <li>Strawberry</li>
      <li>Peach</li>
      <li>Oreo</li>
      <li>Chocolate</li>
      <li>White Chocolate</li>
      <li>Cream Cheese</li>
    </ul>
  </div>
</div>


    )
}

export default CakeFlavors;