import * as Yup from "yup";

const createCakeSchema= Yup.object().shape({
    firstName: Yup.string().required("First Name is required").max(20),
    lastName:Yup.string().required("Last name is required").max(20),
    email: Yup.string().email("Enter a valid email address").required("Email address is required").max(50),
    phoneNumber: Yup.string().matches(/^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/, 'Invalid phone number format').required("Phone number is required"),
    dateOfEvent: Yup.date().required("Date of event is required").min(new Date(), "Date of event must be a future date"),
    cakeSize: Yup.string().required("Cake size is required").max(20),
    cakeFlavor: Yup.string().required("Cake flavor is required"),
    cakeFilling: Yup.string().required("Cake filling is required").max(20),
    cakeType: Yup.string().required("Cake type is required"),
    description: Yup.string().required("Description of your cake is required").max(100),
    imageUrl: Yup.string().nullable(true)

});

export default createCakeSchema;