import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import CakesByMaryNavbar from './components/landingpage/CakeNavbar';
import LandingPage from './components/landingpage/LandingPage';
import Footer from './components/landingpage/Footer';
import CakeFlavors from './components/CakeFlavors';
import MyCalendar from "./components/availabilty/ContactAvailability";
import Policies from "./components/policies/Policies";
import CakeForm from "./components/orderCake/CakeForm";
import Faqs from "./components/faqs/Faqs";
import LoginForm from "./components/login/LoginForm";
import AdminDashboard from "./components/admin/AdminDashboard";
import ProtectedRoute from "./components/ProtectedRoute";


function App() {

  return (
    <Router> 
      <div>
        <CakesByMaryNavbar />
        <Routes>
        <Route path="/" element={<LandingPage />} />
          <Route path="/flavors" element={<CakeFlavors />} />
          <Route path="/contact" element={<MyCalendar />} />
          <Route path="/policies" element={<Policies />} />
          <Route path="/order" element={<CakeForm />} />
          <Route path="/faq" element={<Faqs />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/admin/dashboard" element={
    <ProtectedRoute>
      <AdminDashboard />
    </ProtectedRoute>
  } />

        </Routes>
        <Footer />
      </div>
    </Router>
  );

}

export default App;
