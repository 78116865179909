import axios from "axios";

const reservationService={
    endpoint: "https://cakesbymaryy.azurewebsites.net/api/reservation"
};

reservationService.submitReservation= (reservationData) => {
    console.log("testing",reservationData);
    const config={
        method: "POST",
        url: `${reservationService.endpoint}`,
        data: reservationData,
        withCredentials: true,
        headers:{"Content-Type": "application/json"},
    };
    return axios(config);
}

reservationService.getAllReservations = () => {
    console.log("getting all reservations",);
    const config={
        method: "GET",
        url: `${reservationService.endpoint}`,
        withCredentials: true,
        headers:{"Content-Type": "application/json"},
    };
    return axios(config);
}

reservationService.deleteReservation = (id) => {
    console.log("delete reservation",id);
    const config={
        method: "DELETE",
        url: `${reservationService.endpoint}/${id}`,
        withCredentials: true,
        headers:{"Content-Type": "application/json"},
    };
    return axios(config);
}


reservationService.getPaginated = (pageIndex, pageSize) => {
    const config = {
      method: "GET",
      url: `${reservationService.endpoint}/paginate?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      withCredentials: true,
      crossdomain: true,
      headers: { "Content-Type": "application/json" },
    };
    return axios(config);
};



export default reservationService;