import axios from "axios";

const cakeFormService = {
    endpoint:"https://cakesbymaryy.azurewebsites.net/api"
};

const cakeImageService = {
    endpoint:"https://cakesbymaryy.azurewebsites.net/api"
};

cakeFormService.submitCakeOrder = (cakeData) => {
    console.log("testing", cakeData);
    const config = {
        method: "POST",
        url: `${cakeFormService.endpoint}/cakeorder`,
        data: cakeData,
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
    };
    return axios(config);
}

cakeImageService.uploadImage = (formData) => {
    console.log("FormData:", formData);
    const config = {
        method: "POST",
        url: `${cakeImageService.endpoint}/image`, 
        data: formData,
        withCredentials: true,
    };

    return axios(config);
};

export { cakeFormService, cakeImageService };
