import React, {useState} from 'react';
import { Carousel, Image, Container, Row } from "react-bootstrap"; 
import './CarouselCake.css'
import Cake1 from "../cakepictures/Cake1.jpeg"
import Cake2 from "../cakepictures/Cake2.jpeg"
import Cake3 from "../cakepictures/Cake3.jpeg"
import Cake4 from "../cakepictures/Cake4.jpeg"
import Cake5 from "../cakepictures/Cake5.jpeg"
import Cake6 from "../cakepictures/Cake6.jpeg"
import Cake7 from "../cakepictures/Cake7.jpeg"
import Cake8 from "../cakepictures/Cake8.jpeg"


function CarouselCake() {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
      setIndex(selectedIndex);
    };
  
    return (
      <>
      <div className="content-area">
      <div className="top-text"> 
      <h1>Welcome to Cakes by Mary!</h1>
      <p>Custom cakes in Orange County, lovingly handcrafted in our kitchen to your table.
      </p>
      <p>Our cakes are more than just a treat; they are a personal expression crafted with care. 
      Made from scratch with the finest ingredients and a sprinkle of love, each cake is a homemade 
      masterpiece waiting to be part of your special moments.
      </p>
      </div>
      <div className="carousel-cake">
        <Container>
          <Row>
          <div className="carousel-wrapper">
            <Carousel activeIndex={index} onSelect={handleSelect}>
              <Carousel.Item>
                <Image
                  className=" d-block size-img-cake"
                  src={Cake1}
                  alt="drinks"
                  text="First Slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <Image
                  className=" d-block size-img-cake "
                  src={Cake2}
                  alt="party people"
                  text="Second Slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <Image
                  className=" d-block size-img-cake "
                  src={Cake3}
                  alt="party people"
                  text="Second Slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <Image
                  className=" d-block size-img-cake "
                  src={Cake4}
                  alt="party people"
                  text="Second Slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <Image
                  className=" d-block size-img-cake "
                  src={Cake5}
                  alt="party people"
                  text="Second Slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <Image
                  className=" d-block size-img-cake "
                  src={Cake6}
                  alt="party people"
                  text="Second Slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <Image
                  className=" d-block size-img-cake "
                  src={Cake7}
                  alt="party people"
                  text="Second Slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <Image
                  className=" d-block size-img-cake "
                  src={Cake8}
                  alt="party people"
                  text="Second Slide"
                />
              </Carousel.Item>
            </Carousel>
            </div>
          </Row>
        </Container>
      </div>
      </div>
      </>
    );
  }
  export default CarouselCake;
  