import React, { useState } from 'react';
import './faqs.css'; 

function Faqs() {
  const [openIndex, setOpenIndex] = useState(null); 

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "Do you have pre made cakes?",
      answer: "No, each cake is custom designed and pre ordered to your perfection."
    },
    {
      question: "What is the average cost?",
      answer: "Since each cake is a unique design and custom tailored to your prefrence, there is no 'average' cost."
    },
    {
        question: "Do you deliver?",
        answer: "No, all cake orders are pick ups only in Orange County. "
      },
      {
        question: "What payment methods do you accept?",
        answer: "We accept various payment methods including Apple pay, Venmo, and Zelle."
      },
      {
        question: "How do I place an order?",
        answer: "You can place an order by selecting a date in the Availability/Order page! "
      }
   
  ];

  return (
    <div className="faq-container">
      <h1>Frequently Asked Questions</h1>
      {faqData.map((faq, index) => (
        <div key={index} className="faq-item">
          <button className="faq-question" onClick={() => toggleFAQ(index)}>
            {faq.question}
          </button>
          {openIndex === index && <div className="faq-answer">{faq.answer}</div>}
        </div>
      ))}
    </div>
  );
}

export default Faqs;
