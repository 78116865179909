import React, { useState, useEffect } from 'react';
import blockedDatesService from '../services/blockedDatesService';

const BlockedDates = () => {
  const [blockedDates, setBlockedDates] = useState([]);
  const [newBlockedDate, setNewBlockedDate] = useState('');

  useEffect(() => {
    fetchBlockedDates();
  }, []);

  const fetchBlockedDates = async () => {
    try {
      const response = await blockedDatesService.gettingAllDates(); 
      setBlockedDates(response.data);
    } catch (error) {
      console.error('Error fetching blocked dates:', error);
    }
  };

  const addBlockedDate = async () => {
    try {
      if (newBlockedDate) {
        await blockedDatesService.blockingDate(newBlockedDate); 
        fetchBlockedDates();
        setNewBlockedDate(''); 
      } else {
        console.error('No date selected'); 
      }
    } catch (error) {
      console.error('Error adding blocked date:', error);
    }
  };
  

  const removeBlockedDate = async (id) => {
    try {
      await blockedDatesService.removeDates(id);
      fetchBlockedDates();
    } catch (error) {
      console.error('Error removing blocked date:', error);
    }
  };

  return (
    <div>
      <h2>Blocked Dates Management</h2>
      <div>
        <h3>Current Blocked Dates</h3>
        <ul>
          {blockedDates.map((date) => (
            <li key={date.id}>
              {date.blockedDate}{' '}
              <button onClick={() => removeBlockedDate(date.id)}>Remove</button>
            </li>
          ))}
        </ul>
      </div>
      <div>
        <h3>Add Blocked Date</h3>
        <input
          type="date"
          value={newBlockedDate}
          onChange={(e) => setNewBlockedDate(e.target.value)}
        />
        <button onClick={addBlockedDate}>Add</button>
      </div>
    </div>
  );
};

export default BlockedDates;


